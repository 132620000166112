@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/*
Override Tailwind's rule that breaks react-table-library
.table { display: grid !important }

TODO: use Carina's Table instead of react-table-library
*/
[role='grid'] {
  display: grid !important;
}

@font-face {
  font-family: mp-font;
  font-display: block;
  src:
    url('/matterport/fonts/mp-font.woff2?75dc5a4b72c2c1e1c1d88ed937f6557a')
      format('woff2'),
    url('/matterport/fonts/mp-font.woff?75dc5a4b72c2c1e1c1d88ed937f6557a')
      format('woff'),
    url('/matterport/fonts/mp-font.svg?75dc5a4b72c2c1e1c1d88ed937f6557a#mp-font')
      format('svg');
}

.mp-font {
  /* !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: mp-font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  speak: never;
}

.icon-dollhouse::before {
  content: '\f111';
}

.icon-dpad-up::before {
  content: '\f117';
}

.icon-floor-controls-all::before {
  content: '\f126';
}

.icon-floor-controls::before {
  content: '\f127';
}

.icon-floorplan::before {
  content: '\f128';
}

.icon-panorama::before {
  content: '\f13f';
}

.icon-pause-outline::before {
  content: '\f141';
}

.icon-play::before {
  content: '\f149';
}

.icon-tape-measure::before {
  content: '\f39b';
}

[class*=' icon-'],
[class^='icon-'] {
  /* !important to prevent issues with browser extensions that change fonts */
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: mp-font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
}

[class*=' icon-'] > :not([class^='path']),
[class^='icon-'] > :not([class^='path']) {
  font-family: Roboto, sans-serif;
}

.nova-icon {
  color: inherit;
}

.nova-icon-small {
  font-size: 24px;
  line-height: 24px;
}

.nova-icon-medium {
  font-size: 32px;
  line-height: 32px;
}

.nova-icon-large {
  font-size: 52px;
  line-height: 52px;
}
